<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";

import Stat from "@/components/widgets/stat";
import SalesAnalytics from "./sales-analytics";
// import { fabric } from "fabric";

// import SellingProduct from './selling-product';
// import TopUsers from './top-users';
// import Activity from './activity';
// import SocialSource from './social-source';

export default {
  page: {
    title: "หน้าหลัก",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
    Stat,
    SalesAnalytics,
    
  },
  data() {
    return {
      user:'',
      title: "หน้าหลัก",
      branchId: "",
      localDataBranch: "",
      dataDashboard:'',
      DateSearch: "",
      localData: [],
      items: [
        {
          text: "SuriyaIT",
        },
        {
          text: "หน้าหลัก",
          active: true,
        },
      ],
    };
  },
  created() {
    this.getLocalData();
  },
  
  methods: {
    
    getLocalData() {
      const user = JSON.parse(localStorage.getItem(appConfig.storageUserKey));
      this.localData = user.userAccessBranch;
      // console.log('localdata =>', this.localData);
      this.user = user;
      // console.log('user;;;', user);
      this.localDataBranch = user.userAccessBranch;
      this.branchId = user.branchId;
      const localDataBranch = [];
      this.localData.forEach((item) => {
        localDataBranch.push(item.branchId);
      });
      this.localDataBranch = localDataBranch;
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-6">
        <!-- <canvas ref="can" width="200" height="200"></canvas> -->
      </div>
      <div class="card">
        <div class="card-body">
          <b style="font-size:30px;">สวัสดี</b><br />

          <b
            >คุณ {{ user.name }}
            {{ user.familyName === null ? "" : user.familyName }}</b
          ><br />
          {{ dataDashboard }}
          <!-- ประจำสาขา : {{localData.userAccessBranch[0].nameTh}} -->
        </div>
        <!-- end card-body-->
      </div>
    </div>
      <stat :branchId="branchId.branchId" :date="DateSearch"></stat>
      <SalesAnalytics></SalesAnalytics>
  </Layout>
</template>
