<script>
import countTo from "vue-count-to";
import Swal from "sweetalert2";
import useNetw from "@useNetw";
import appConfig from "@/app.config";
import tableData from "@/components/tablecomponent";
import Multiselect from "vue-multiselect";
import DatePicker from "vue2-datepicker";
import btnComponent from "@/components/btnComponent";
/**
 * Stat component
 */
export default {
  components: {
    countTo,
    tableData,
    Multiselect,
    DatePicker,
    btnComponent,
  },

  data() {
    return {
      branchId: [],
      branchIdFirst: [],
      localData: "",
      localDataBranch: [],
      DateSearch: "",
      dataDashboard: [],
      itemWIP: [],
      itemAppt: [],
      itemRepair: [],
      itemFinish: [],
      showTB: [],
      title: "",
      fields: [],
      series: [
        {
          data: [25, 66, 41, 89, 63, 25, 44, 20, 36, 40, 54],
        },
      ],
      chartOptions: {
        fill: {
          colors: ["#5b73e8"],
        },
        chart: {
          type: "bar",
          sparkline: {
            enabled: true,
          },
        },
        plotOptions: {
          bar: {
            columnWidth: "50%",
          },
        },
        labels: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
        xaxis: {
          crosshairs: {
            width: 1,
          },
        },
        tooltip: {
          fixed: {
            enabled: false,
          },
          x: {
            show: false,
          },
          y: {
            title: {
              formatter: function () {
                return "";
              },
            },
          },
          marker: {
            show: false,
          },
        },
      },
      growthChartOptions: {
        fill: {
          colors: ["#f1b44c"],
        },
        chart: {
          type: "bar",
          sparkline: {
            enabled: true,
          },
        },
        plotOptions: {
          bar: {
            columnWidth: "50%",
          },
        },
        labels: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
        xaxis: {
          crosshairs: {
            width: 1,
          },
        },
        tooltip: {
          fixed: {
            enabled: false,
          },
          x: {
            show: false,
          },
          y: {
            title: {
              formatter: function () {
                return "";
              },
            },
          },
          marker: {
            show: false,
          },
        },
      },
      orderseries: [70],
      orderRadial: {
        fill: {
          colors: ["#34c38f"],
        },
        chart: {
          sparkline: {
            enabled: true,
          },
        },
        dataLabels: {
          enabled: false,
        },
        plotOptions: {
          radialBar: {
            hollow: {
              margin: 0,
              size: "60%",
            },
            track: {
              margin: 0,
            },
            dataLabels: {
              show: false,
            },
          },
        },
      },
      customerseries: [55],
      customerRadial: {
        fill: {
          colors: ["#5b73e8"],
        },
        chart: {
          sparkline: {
            enabled: true,
          },
        },
        dataLabels: {
          enabled: false,
        },
        plotOptions: {
          radialBar: {
            hollow: {
              margin: 0,
              size: "60%",
            },
            track: {
              margin: 0,
            },
            dataLabels: {
              show: false,
            },
          },
        },
      },
    };
  },
  created() {
    this.getNow();
    this.getData();
  },
  methods: {
    local() {
      const user = JSON.parse(localStorage.getItem(appConfig.storageUserKey));
      this.localData = user;
      this.localDataBranch = user.userAccessBranch;
      this.branchId = user.branchId;
      this.branchIdFirst = user.branchId;
      const localDataBranch = [];
      this.localData.forEach((item) => {
        localDataBranch.push(item.branchId);
      });
      this.localDataBranch = localDataBranch;
    },

    getNow() {
      const today = new Date();
      const date =
        today.getFullYear() +
        "-" +
        (today.getMonth() + 1 > 9 ? "" : "0") +
        (today.getMonth() + 1) +
        "-" +
        (today.getDate() > 9 ? "" : "0") +
        today.getDate();

      this.DateSearch = date;
      // console.log(this.DateSearch);
    },
    getData() {
      useNetw

        .get("api/home", {
          params: {
            branchId: this.branchId,
            date: this.DateSearch,
          },
        })
        .then((response) => {
          // console.log("Data=>", response.data);
          this.dataDashboard = response.data;
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {});
    },
    popupModal(data) {
      if (data == "wip") {
        this.showTB = this.dataDashboard.wip;
        this.title = "ข้อมูลรถระหว่างทำ";
        this.fields = [
          {
            key: "index",
            label: "ลำดับ",
          },
          {
            key: "repairDate",
            label: "วันที่",
          },
          {
            key: "licensePlate",
            label: "ทะเบียน",
          },
          {
            key: "modelNameEn",
            label: "รุ่นรถ",
          },
          {
            key: "distanceNameTh",
            label: "ประเภทการเข้าซ่อม",
          },
        ];
      } else if (data == "appt") {
        this.showTB = this.dataDashboard.appointment;
        this.title = "ข้อมูลรถนัดหมาย";
        this.fields = [
          {
            key: "index",
            label: "ลำดับ",
          },
          {
            key: "apptDate",
            label: "วันที่",
          },
          {
            key: "apptTime",
            label: "เวลานัด",
          },
          {
            key: "vehiclePlate",
            label: "ทะเบียน",
          },
          {
            key: "modelNameEn",
            label: "รุ่นรถ",
          },
          {
            key: "customerName",
            label: "ชื่อลูกค้า",
          },
          {
            key: "customerMobilePhone",
            label: "เบอร์โทรศัพท์",
          },
          {
            key: "distanceNameTh",
            label: "ประเภทการเข้าซ่อม",
          },
        ];
      } else if (data == "repair") {
        this.showTB = this.dataDashboard.repair;
        this.title = "ข้อมูลรถซ่อม";
        this.fields = [
          {
            key: "index",
            label: "ลำดับ",
          },
          {
            key: "licensePlate",
            label: "ทะเบียน",
          },

          {
            key: "modelNameEn",
            label: "รุ่นรถ",
          },
          {
            key: "customerName",
            label: "ชื่อลูกค้า",
          },
          {
            key: "distanceNameTh",
            label: "ประเภทการเข้าซ่อม",
          },
        ];
      } else if (data == "finish") {
        this.showTB = this.dataDashboard.finish;
        this.title = "สำเร็จ";
      }
      this.$refs["modalShow"].show();
    },
    handleHide() {
      this.showTB = [];
    },
  },
};
</script>

<template>
  <div class="row text-center ml-3">
    <div class="row">
      <div class="card">
        <div class="row mt-4">
          <div class="col-md-7">
            <div class="mb-3 position-relative">
              <label for="branchId">สาขา</label><br />
              <multiselect
                v-model="branchId"
                :options="localDataBranch"
                label="nameTh"
                @input="getData()"
              >
              </multiselect>
            </div>
          </div>
          <div class="col-md-3">
            <label for="branchId">วันที่</label><br />
            <date-picker
              v-model="DateSearch"
              :first-day-of-week="1"
              format="YYYY-MM-DD"
              value-type="format"
              lang="en"
            ></date-picker>
          </div>
          <div class="col-md-2 text-end">
            <label for="btn">&nbsp;</label><br />
            <btnComponent
              changeStyle="search"
              @click="getData()"
            ></btnComponent>
          </div>
        </div>
      </div>
      <hr />
    </div>
    <b-modal
      ref="modalShow"
      :title="title"
      hide-footer
      size="xl"
      @hide="handleHide"
    >
      <div class="row">
        <tableData :fields="fields" :items="showTB" v-show="showTB.length > 0">
          <template #cell(index)="showTB">
            {{ showTB.index + 1 }}
          </template>
        </tableData>
        <div class="col-md-12 text-center">
          <b v-show="showTB.length == 0">ไม่พบข้อมูล</b>
        </div>
      </div>
    </b-modal>
    <div class="col-md-6 col-xl-3" @click="popupModal('appt')">
      <div class="card">
        <div class="card-body">
          <div class="float-end mt-2">
            <i class="uil uil-calendar-alt" style="font-size: 70px"></i>
          </div>
          <div>
            <h4 class="mb-1 mt-1">
              นัดหมาย<br />
              <span data-plugin="counterup">
                <h2>
                  <countTo
                    :startVal="0"
                    :endVal="
                      dataDashboard.appointment
                        ? dataDashboard.appointment.length
                        : 0
                    "
                    :duration="2000"
                  ></countTo>
                </h2>
              </span>
              คัน
            </h4>
            <p class="text-muted mb-0">คลิกเพื่อดูรายละเอียด</p>
          </div>
        </div>
      </div>
    </div>

    <div class="col-md-6 col-xl-3" @click="popupModal('repair')">
      <div class="card">
        <div class="card-body">
          <div class="float-end mt-2">
            <i class="uil uil-car" style="font-size: 70px; color: red"></i>
          </div>
          <div>
            <h4 class="mb-1 mt-1">
              เข้ารับบริการ<br />
              <span data-plugin="counterup">
                <h2>
                  <countTo
                    :startVal="0"
                    :endVal="
                      dataDashboard.repair ? dataDashboard.repair.length : 0
                    "
                    :duration="2000"
                  ></countTo>
                </h2>
              </span>
              คัน
            </h4>
            <p class="text-muted mb-0">คลิกเพื่อดูรายละเอียด</p>
          </div>
        </div>
      </div>
    </div>

    <div class="col-md-6 col-xl-3" @click="popupModal('wip')">
      <div class="card">
        <div class="card-body">
          <div class="float-end mt-2">
            <i
              class="mdi mdi-progress-wrench"
              style="font-size: 70px; color: orange"
            ></i>
          </div>
          <div>
            <h4 class="mb-1 mt-1">
              ดำเนินการ<br />
              <span data-plugin="counterup">
                <h2>
                  <countTo
                    :startVal="0"
                    :endVal="dataDashboard.wip ? dataDashboard.wip.length : 0"
                    :duration="2000"
                  ></countTo>
                </h2>
              </span>
              คัน
            </h4>
            <p class="text-muted mb-0">คลิกเพื่อดูรายละเอียด</p>
          </div>
        </div>
      </div>
    </div>

    <div class="col-md-6 col-xl-3" @click="popupModal('finish')">
      <div class="card">
        <div class="card-body">
          <div class="float-end mt-2">
            <i
              class="mdi mdi-car-arrow-right"
              style="font-size: 70px; color: green"
            ></i>
          </div>
          <div>
            <h4 class="mb-1 mt-1">
              เสร็จสิ้น<br />
              <span data-plugin="counterup">
                <h2>
                  <countTo
                    :startVal="0"
                    :endVal="
                      dataDashboard.finish ? dataDashboard.finish.length : 0
                    "
                    :duration="2000"
                  ></countTo>
                </h2>
              </span>
              คัน
            </h4>
            <p class="text-muted mb-0">คลิกเพื่อดูรายละเอียด</p>
          </div>
        </div>
      </div>
    </div>

    <!-- end col-->
  </div>
  <!-- end row-->
</template>
