<script>
import countTo from "vue-count-to";

import appConfig from "@/app.config";
import Swal from "sweetalert2";
import useNetw from "@useNetw";
/**
 * Sales-analytics component
 */
export default {
  components: {
    countTo,
  },
  created() {
    this.getNow();
    this.getData();
  },
  data() {
    return {
      DateSearch: "",
      dataDashboard: "",
      totalSales: 0,
      overlay: false,
      chart: false,

      seriesVm : [],
      dataVm:[],
      totalVm: 0,


      //? วัดผล
      options: {
        chart: {
          id: "saPerformance",
        },
        xaxis: {
          title: "ยอดขาย",
          categories: [],
        },
        
        plotOptions: {
          bar: {
            columnWidth: "10%",
          },
        },
        colors: [ "#f1b44c"],
        fill: {
          opacity: [0.85, 0.25, 1],
          gradient: {
            inverseColors: false,
            shade: "light",
            type: "vertical",
            opacityFrom: 0.85,
            opacityTo: 0.55,
            stops: [0, 100, 100, 100],
          },
        },
        markers: {
          size: 0,
        },
        tooltip: {
          shared: true,
          intersect: false,
          y: {
            formatter: function(y) {
              if (typeof y !== "undefined") {
                return y.toFixed(2) + " บาท";
              }
              return y;
            },
          },
        },
        grid: {
          borderColor: "#f1f1f1",
        },
      },
      series: [
        {
          name: "ยอดขาย",
          data: [],
        },
      ],//? end วัดผล

      //! รถ

      optionsVm: {
        chart: {
          id: "Vehicles",
        },
        xaxis: {
          title: "จำนวนรถ",
          categories: [],
        },
        
        plotOptions: {
          bar: {
            columnWidth: "10%",
          },
        },
        colors: [ "#5b73e8"],
        fill: {
          opacity: [0.85, 0.25, 1],
          gradient: {
            inverseColors: false,
            shade: "light",
            type: "vertical",
            opacityFrom: 0.85,
            opacityTo: 0.55,
            stops: [0, 100, 100, 100],
          },
        },
        markers: {
          size: 0,
        },
        tooltip: {
          shared: true,
          intersect: false,
          y: {
            formatter: function(y) {
              if (typeof y !== "undefined") {
                return y + " คัน";
              }
              return y;
            },
          },
        },
        grid: {
          borderColor: "#f1f1f1",
        },
      },
      Vmseries: [
        {
          name: "จำนวนรถ",
          data: [],
        },
      ],
    };
    

    //! จำนวนรถแยกตามรุ่น 
    
  },
  mounted() {
    const user = JSON.parse(localStorage.getItem(appConfig.storageUserKey));
    localStorage.setItem("notify", user.notification);
    // console.log(user.notification)
  },
  methods: {
    getNow() {
      const today = new Date();
      const date =
        today.getFullYear() +
        "-" +
        (today.getMonth() + 1 > 9 ? "" : "0") +
        (today.getMonth() + 1) +
        "-" +
        (today.getDate() > 9 ? "" : "0") +
        today.getDate();

      this.DateSearch = date;
      // console.log(this.DateSearch);
    },
    getData() {
      this.overlay = true;
      useNetw
        .get("api/home", {
          params: {
            branchId: this.branchId,
            date: this.DateSearch,
            // date: "2021-10-13",
          },
        })
        .then((response) => {
        //   console.log("Data Sales=>", response.data);
          this.dataDashboard = response.data;

            //? SA  
          const SA = response.data.saPerformance;
          // console.log('SA=>' , SA);
          const saName = [];
          const saSeries = [];
          let saTotal = 0;
          SA.forEach((itemSA) => {
            saName.push(itemSA.saName == null ? 'ไม่พบชื่อที่ปรีกษางานบริการ' : itemSA.saName);
            saSeries.push(itemSA.grandTotalPrice);
            saTotal += parseFloat(itemSA.grandTotalPrice);
          });
          this.options.xaxis.categories = saName;
          this.series[0].data = saSeries;
          
          this.totalSales = saTotal;
          // console.log('totalsale => ', this.totalSales);
          


          //! Vehicles
          const Vm = response.data.vehicleModel;
          const Vehicles = [];
          const vmSeries = [];
          Vm.forEach((itemVm)=> {
              Vehicles.push(itemVm.modelNameEn);
              vmSeries.push(itemVm.countSv);
              this.totalVm += itemVm.countSv;
          });
        //   console.log('รุ่นรถ =>', Vehicles);
        //   console.log('จำนวนรถ =>' , vmSeries);
          this.optionsVm.xaxis.categories = Vehicles;
          this.Vmseries[0].data = vmSeries;

          this.chart = true;

        
        //   console.log("คน=>", this.options.xaxis.categories);
        //   console.log("ยอดขาย=>", this.series[0].data);

          this.overlay = false;
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {});
    },
  },
};
</script>

<template>
  <div class="col-xl-12">
    <div class="card">
      <b-overlay
        :show="overlay"
        :spinner-variant="this.$overlayVariant"
        :spinner-type="this.$overlayType"
        :rounded="this.$overlayRounded"
      >
        <div class="card-body">
          <h4 class="card-title mb-4">วัดประสิทธิภาพการทำงานของที่ปรีกษางานบริการ</h4>
          <div class="mt-1">
            <ul class="list-inline main-chart mb-0">
              <li class="list-inline-item chart-border-left me-0 border-0">
                <h3 class="text-primary">
                  ยอดรวม
                  <span data-plugin="counterup">
                    <countTo
                      :startVal="1"
                      :endVal="totalSales"
                      :duration="2000"
                    ></countTo>
                  </span>
                  <span class="text-muted d-inline-block font-size-15 ms-3"
                    >บาท</span>
                </h3>
              </li>
            </ul>
          </div>
          <div class="row">
            <div class="col-md-12 mt3">
              <apexchart
                v-if="chart == true && this.series[0].data.length > 0"
                type="bar"
                class="apex-charts"
                dir="ltr"
                height="500"
                :options="options"
                :series="series"
              ></apexchart>
              <div class="text-center" v-else>
                ไม่พบข้อมูล
              </div>
            </div>
          </div>
        </div>
        <!-- end card-body-->
      </b-overlay>
    </div>
    <!-- end card-->


    <div class="card">
      <b-overlay
        :show="overlay"
        :spinner-variant="this.$overlayVariant"
        :spinner-type="this.$overlayType"
        :rounded="this.$overlayRounded"
      >
        <div class="card-body">
          <h4 class="card-title mb-4">จำนวนรถที่เข้ารับบริการตามรุ่น</h4>
          <div class="mt-1">
            <ul class="list-inline main-chart mb-0">
              <li class="list-inline-item chart-border-left me-0 border-0">
                <h3 class="text-primary">
                  จำนวนรถที่เข้ารับบริการทั้งหมด
                  <span data-plugin="counterup">
                    <countTo
                      :startVal="1"
                      :endVal="totalVm"
                      :duration="2000"
                    ></countTo>
                  </span>
                  <span class="text-muted d-inline-block font-size-15 ms-3"
                    >คัน</span>
                </h3>
              </li>
            </ul>
          </div>
          <div class="row">
            <div class="col-md-12 mt3">
              <apexchart
                v-if="chart == true && this.Vmseries[0].data.length > 0"
                type="bar"
                class="apex-charts"
                dir="ltr"
                height="500"
                :options="optionsVm"
                :series="Vmseries"
              ></apexchart>
              <div class="text-center" v-else>
                ไม่พบข้อมูล
              </div>
            </div>
          </div>
        </div>
        <!-- end card-body-->
      </b-overlay>
    </div>
    <!-- end card-->
  </div>
  <!-- end col-->
  
</template>
